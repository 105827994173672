<template>
  <div class="payment-preferences">
    <error :err.sync="error" />
    <spinner v-if="loading || !paymentPreferences" />
    <template v-else>
      <form-generator
        :elements="elements"
        :data="paymentPreferences"
        :handleUpdate="({ key }, value) => (paymentPreferences[key] = value)"
      />
    </template>
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator";
import { kebab } from "case";
import { find, map, pickBy, mapKeys } from "lodash-es";
import { mapState, mapGetters } from "vuex";
import paymentMethodFields from "../../../fixtures/payment-method-fields";

export default {
  name: "payment-preferences",
  components: {
    FormGenerator
  },
  data() {
    return {
      loading: true,
      saving: false,
      removing: false,
      paymentPreferences: {
        payment_method_id: null,
        payment_frequency_id: null,
        minimum_payment_balance_id: null,
        currency_id: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        is_uk_vat_registered: false,
        allow_alternative_person_access: false
      },
      error: null
    };
  },
  async mounted() {
    this.loading = true;
    await this.getPaymentPreferences();
    await this.$store.dispatch("ancillaries/getPaymentMethods");
    await this.$store.dispatch("ancillaries/getPaymentFrequencies");
    await this.$store.dispatch("ancillaries/getMinimumPaymentBalances");
    await this.$store.dispatch("ancillaries/getCurrencies");
    this.loading = false;
  },
  computed: {
    ...mapState("ancillaries", [
      "paymentMethods",
      "paymentFrequencies",
      "minimumPaymentBalances",
      "currencies"
    ]),
    ...mapGetters("ancillaries", ["paymentMethodsMap"]),
    paymentMethodsDropdown() {
      return this.paymentMethods.map(m => ({
        text: m.name,
        value: m.code
      }));
    },
    minimumPaymentBalancesDropdown() {
      return this.minimumPaymentBalances.map(m => ({
        text: m.balance,
        value: m.balance
      }));
    },
    allowedCurrencies() {
      const selectedMethod = find(
        this.paymentMethods,
        o => o.id === this.paymentPreferences.payment_method_id
      );
      return selectedMethod
        ? this.currencies.filter(c =>
            selectedMethod.currencies.map(c => c.id).includes(c.id)
          )
        : this.currencies;
    },
    allowedBalances() {
      const selectedMethod = find(
        this.paymentMethods,
        o => o.id === this.paymentPreferences.payment_method_id
      );

      return selectedMethod && selectedMethod.minimum_payment_amount
        ? this.minimumPaymentBalances.filter(
            m => m.balance >= selectedMethod.minimum_payment_amount
          )
        : this.minimumPaymentBalances;
    },
    elements() {
      let elements = [
        {
          text: "bank-fee-disclaimer",
          type: "paragraph"
        },
        {
          label: "preferred-currency",
          key: "currency_id",
          type: "select",
          attrs: {
            options: this.addSelectOption(this.allowedCurrencies, "id", "title")
          }
        },
        {
          label: "payment-method",
          key: "payment_method_id",
          type: "select",
          attrs: {
            options: this.addSelectOption(
              this.paymentMethods.filter(item => !item.name.includes("Legacy")),
              "id",
              "name"
            )
          }
        },
        {
          key: "payment_frequency_id",
          label: "payment-frequency",
          type: "select",
          attrs: {
            options: this.addSelectOption(this.paymentFrequencies, "id", "name")
          }
        },
        {
          key: "minimum_payment_balance_id",
          label: "minimum-payment-balance",
          type: "select",
          attrs: {
            options: this.addSelectOption(this.allowedBalances, "id", "balance")
          }
        }
      ];

      const paymentMethodCode = this.paymentMethodsMap[
        this.paymentPreferences.payment_method_id
      ]?.code;

      paymentMethodCode &&
        (elements = [
          ...elements,
          {
            text: "payment-information",
            type: "divider",
            className: "mt-5"
          }
        ]);

      // payment values
      if (paymentMethodFields[paymentMethodCode]) {
        elements = [
          ...elements,
          ...map(paymentMethodFields[paymentMethodCode], (type, key) => ({
            key: "payment_details." + key,
            label: kebab(key),
            type
          }))
        ];
      }

      elements = [
        ...elements,
        {
          type: "divider",
          text: "alternative-contact-details",
          className: "mt-5"
        },
        {
          type: "paragraph",
          text: "alternative-contact-details-text-1"
        },
        {
          type: "paragraph",
          text: "alternative-contact-details-text-2"
        },
        {
          type: "string",
          key: "first_name",
          label: "first-name"
        },
        {
          type: "string",
          key: "last_name",
          label: "last-name"
        },
        {
          type: "email",
          key: "email",
          label: "email"
        },
        {
          type: "number",
          key: "phone",
          label: "phone"
        },
        {
          type: "switch",
          label: "alternative-contact-details-text-3",
          key: "allow_alternative_person_access"
        },
        {
          type: "divider",
          text: "self-billing-vat-uk",
          className: "mt-5"
        },
        {
          type: "paragraph",
          text: "self-billing-message-1"
        },
        {
          type: "paragraph",
          text: "self-billing-message-2"
        },
        {
          type: "paragraph",
          text: "self-billing-message-3"
        },
        {
          type: "paragraph",
          text: "self-billing-message-4"
        },
        {
          type: "switch",
          label: "are-you-registered-vat-uk",
          key: "is_uk_vat_registered"
        },
        {
          showIf: ["is_uk_vat_registered"],
          type: "paragraph",
          html: "uk-registered-billing-agreement",
          className: "font-weight-bolder lead"
        },
        {
          type: "button-group",
          className: "float-right",
          elements: [
            {
              text: "delete",
              type: "button",
              className: "px-5 float-right mt-3 btn-danger",
              attrs: {
                loading: this.removing
              },
              events: {
                click: this.remove
              }
            },
            {
              type: "button",
              text: "save",
              className: "px-5 float-right mt-3",
              attrs: {
                loading: this.saving
              },
              events: {
                click: this.save
              }
            }
          ]
        }
      ];

      return elements;
    }
  },
  methods: {
    kebab,
    async getPaymentPreferences() {
      try {
        const res = await this.$store.dispatch("users/getPaymentPreferences");

        const { payment_method_details } = res.data;

        this.paymentPreferences = {
          ...this.paymentPreferences,
          ...res.data,
          ...mapKeys(payment_method_details, (_, k) => "payment_details." + k)
        };
      } catch (err) {
        console.error(err);
      }
    },
    async remove() {
      if (confirm(this.$t("remove-preferences"))) {
        try {
          this.removing = true;
          await this.$store.dispatch(
            "userPreferences/deletePaymentPreferences"
          );
          this.getPaymentPreferences();
        } catch (err) {
          console.error(err);
        }
        this.removing = false;
      }
    },
    async save() {
      this.error = null;
      this.saving = true;

      const { paymentPreferences } = this;

      try {
        await this.$store.dispatch("userPreferences/updatePaymentPreferences", {
          ...paymentPreferences,
          payment_method_details: mapKeys(
            pickBy(paymentPreferences, (v, k) =>
              k.includes("payment_details.")
            ),
            (_, k) => k.replace("payment_details.", "")
          )
        });
      } catch (err) {
        console.error(err);
      }

      this.saving = false;
    }
  },
  watch: {
    "paymentPreferences.payment_method_id"(id) {
      const selectedMethod = find(this.paymentMethods, o => o.id === id);

      // select currency is not allowed
      if (
        selectedMethod &&
        !selectedMethod.currencies
          .map(c => c.id)
          .includes(this.paymentPreferences.currency_id)
      ) {
        this.paymentPreferences.currency_id = null;
      }

      const selectedMinimumPaymentBalance = find(
        this.minimumPaymentBalances,
        o => o.id === this.paymentPreferences.minimum_payment_balance_id
      );

      // selected minimum payment is not allowed
      if (
        selectedMinimumPaymentBalance &&
        selectedMethod &&
        selectedMethod.minimum_payment_amount &&
        selectedMethod.minimum_payment_amount >
          selectedMinimumPaymentBalance.balance
      ) {
        this.paymentPreferences.minimum_payment_balance_id = null;
      }
    }
  }
};
</script>

<style></style>
